(function(window, document) {

	// ---------------------------------------------- [START] 倒數
	// 日期從 0 開始
	function addZeroStart(text) {
		return ('0' + text ).slice(-2);
	}

	// 倒數
	function buildCountDown() {
		var countdownEl = $('#countdown');
		var date = countdownEl.data('date');
		var year = countdownEl.find('.year');
		var month = countdownEl.find('.month');
		var day = countdownEl.find('.day');
		var time = countdown( new Date(date) );

		year.text(addZeroStart(time.years));
		month.text(addZeroStart(time.months));
		day.text(addZeroStart(time.days));
	}
	window.addEventListener('load', buildCountDown );
	// ---------------------------------------------- [END] 倒數
	


	// ---------------------------------------------- [START] 路線選擇(紅色很多背景圖的區塊)
	$('.index-sure__route__title-box').on('click', function () {
		if (window.innerWidth < 640) {
			// 手機要可收合
			$(this).parents('.index-sure__route__item').siblings().removeClass('is-active');
			$(this).parents('.index-sure__route__item').toggleClass('is-active');
		}
	});
	// ---------------------------------------------- [END] 路線選擇(紅色很多背景圖的區塊)



	/* ---------------------------------------- [START] ScrollTriggerAni */
	// ★★★★★★★★★★★ 因為整個區塊已設定 Vue 所以global 設定的 function 不管用，改在檔案內使用
	// 
	// 有滑動到該區增加動態者增加class "js-ani" ， 滑動到該區增加class "is-animated"
	// 用套件也好，換其它習慣方式也罷，請達成↑目的
	function scrollTriggerInit(el = document) {

		let aniSec = el.querySelectorAll('.js-ani');

		const scrollTriggerAniThrottle = throttle(scrollTriggerAni, 200, 500); // 節流作用
	
		function scrollTriggerAni() {
			for (var i = 0; i < aniSec.length; i++) {
				if (isInViewport(aniSec[i])) {
					aniSec[i].classList.add('is-animated');
				}
			}
	
			cleanTrigger();
	
			// If load all of the item, stop Listener
			if (aniSec.length === 0) {
				window.removeEventListener('scroll', scrollTriggerAniThrottle);
			}
		}
	
		function cleanTrigger() {
			aniSec = Array.prototype.filter.call(aniSec, function (ani) {
				return !ani.classList.contains('is-animated');
			});
		}

		scrollTriggerAni();
		window.addEventListener('scroll', scrollTriggerAniThrottle);
	}

	/* Tool ----------------------------------------- */
	function isInViewport(el) {
		const rect = el.getBoundingClientRect();
		const isVisible = el.offsetHeight !== 0;

		return (
			isVisible &&
			rect.bottom >= 0 &&
			rect.right >= 0 &&
			rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
			rect.left <= (window.innerWidth || document.documentElement.clientWidth)
		);
	}
	// https://www.cnblogs.com/coco1s/p/5499469.html
	// 簡單的節流函數
	function throttle(func, wait, mustRun) {
		var timeout;
		var startTime = new Date();

		return function () {
			var context = this;
			var args = arguments;
			var curTime = new Date();

			clearTimeout(timeout);
			// 如果達到了規定的觸發時間間隔，觸發 handler
			if (curTime - startTime >= mustRun) {
				func.apply(context, args);
				startTime = curTime;
				// 沒達到觸發間隔，重新設定定時器
			} else {
				timeout = setTimeout(func, wait);
			}
		};
	}
	/* ---------------------------------------- [END] ScrollTriggerAni */



	// ---------------------------------------------- [START] 計算(Vue)
	// https://stackoverflow.com/a/61095881
	function effect(rate, npery){
		npery = parseInt(npery);
		if (( npery == 0 ) || ( rate == 0 )){ return(0); }
		rate = eval((rate)/(100));
		effect_value = ((Math.pow(1 + (rate/npery), npery)) - 1) * 100;
		return (effect_value);
	}

	// 每月存款金額比較
	// 因為沒公式，只有表(JPG)，只能手KEY
	var saveMonthData = {
		5000: {
			25: 4585950,
			35: 2893565,
			45: 1634272,
			55: 697240
		},
		8000: {
			25: 7337519,
			35: 4629704,
			45: 2614836,
			55: 1115584
		},
		10000: {
			25: 9171899,
			35: 5787130,
			45: 3268544,
			55: 1394480
		},
		15000: {
			25: 13757849,
			35: 8680696,
			45: 4902817,
			55: 2091720
		},
	}
	var saveStartAgeData = [25, 35, 45, 55];
	var savePerMonthMoneyData = [5000, 8000, 10000, 15000];

	var vmElSelector = '.index-calc';
	function buildVue() {
		var vm = new Vue({
			el: vmElSelector,
			// Cleave.js - 欄位格式化工具
			// Ref: https://ithelp.ithome.com.tw/articles/10197884
			// Ref: https://nosir.github.io/cleave.js/
			// Ref: https://github.com/nosir/cleave.js/blob/master/doc/vue.md
			directives: {
				cleave: {
					inserted: (el, binding) => {
						el.cleave = new Cleave(el, binding.value || {})
					},
					update: (el, binding, vnode) => {
						const event = new Event('input', { bubbles: true })
						setTimeout(function () {
							// 如果是空值(重設) => 不動作
							if (el.value !== '') {
								el.value = el.cleave.properties.result
								el.dispatchEvent(event)
							}
						}, 100)
					}
				}
			},
			data: {
				// 表單項目
				gender: null, // 性別
				age: '', // 年紀
				incomeInput: '', // 年收入(輸入用，會有逗號)
				// income: '', // 年收入
				depositInput: '', // 存款(輸入用，會有逗號)
				// deposit: '', // 存款
				offAge: '', // 你想幾歲退休
	
				// 判斷結果
				isShow: false, // 是否顯示
				showError: false, // 是否有錯誤

				// ------------------- [START] 測試時要預設出現數字（帶入預設值、原本的要註解）
				// age: '25',　// 年紀
				// incomeInput: '600,000',　// 年收入(輸入用，會有逗號)
				// depositInput: '300,000',　// 存款(輸入用，會有逗號)
				// offAge: '65',　// 你想幾歲退休
				// isShow: true, // 是否顯示
				// ------------------- [END] 測試時要預設出現數字（帶入預設值、原本的要註解）
				
				// 每月存款金額比較 資料
				saveMonthData: saveMonthData,
				saveStartAgeData: saveStartAgeData,
				savePerMonthMoneyData: savePerMonthMoneyData,
				saveStateAge: 35,
				savePerMonthMoney: 5000
			},
			computed: {
				income: function() {
					return this.incomeInput.replace(/,/g, '');
				},
				deposit: function() {
					return this.depositInput.replace(/,/g, '');
				},
				// -------------------------
				// 退休金
				pension: function() {
					return this.deposit * (this.offAge - this.age);
				},
				// +勞保
				labor: function() {
					// return Math.round(Math.max(((this.income/12)*(40)*0.775/100+3000)*12*(81-this.offAge),(this.income/12)*(40)*1.55/100*12*(81-this.offAge))+((40*this.income*6/100)/20.91754));
					return Math.round(Math.max(((this.income/12)*(40)*0.775/100+3000)*24,(this.income/12)*(40)*1.55/100*24)+((1.2766165*40*this.income*6/100)/20.91754)*24);
				},
				// 每個月可以花
				canSpend: function() {
					return Math.round(this.pension/(23.5*12));
				},
				// 所得替代率
				incomeReplacementRatio: function() {
					return Math.round(((this.canSpend*12)/this.income) * 100 * 100) / 100;
				},
				// 以外籍看護來算，你的退休金扣除長照費僅剩(你準備的 在你81歲時)
				minusForeignNursing: function() {
					return this.pension - 2190000
				},
				// 價值大約只等於
				valueEqual: function() {
					if (!this.isShow) { return null }
					return Math.round(this.minusForeignNursing/(effect(101.4,( (this.gender === 'male' ? 81 : 84) - this.age))) * 100)
				},
				// 算下來每個月只有 ??? 可以用
				perMonthCanUse: function() {
					return Math.round(this.valueEqual/(12 * ( (this.gender === 'male' ? 81 : 84) - this.offAge)))
				},
				// 每月存款金額比較
				saveMonthDataResult: function() {
					return this.saveMonthData[this.savePerMonthMoney][this.saveStateAge]
				},
				// ----------------------
				// 表單驗證
				genderError: function() {
					return this.gender !== 'male' && this.gender !== 'female' 
				},
				ageError: function() {
					return this.age.trim() === ''
				},
				incomeError: function() {
					return this.incomeInput.trim() === ''
				},
				depositError: function() {
					return this.depositInput.trim() === ''
				},
				offAgeError: function() {
					return this.offAge.trim() === ''
				},
				
				error: function() {
					if (this.genderError) {
						return true
					}
					if (this.ageError) {
						return true
					}
					if (this.incomeError) {
						return true
					}
					if (this.depositError) {
						return true
					}
					if (this.offAgeError) {
						return true
					}
					return false
				}
			},
			methods: {
				parseIntNumber: function(str) {
					return parseInt(str)
				},
				formatPrice: function(value) {
					return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null
				},
				showResult: function() {
					if (this.error) {
						this.showError = true
					} else {
						this.showError = false
						this.isShow = true;
					}
				}
			},
			mounted: function() {
				// 因為 DOM 會重新生成，所以放在 mounted 延後生成

				// 1. Lazyload
				const images = document.querySelectorAll(vmElSelector + ' img');
				lazyload(images);

				// 2. scrollTrigger
				scrollTriggerInit(document.querySelector(vmElSelector));
			}
		})
	}
	window.addEventListener('load', buildVue );
	// ---------------------------------------------- [END] 計算(Vue)
})(window, document);
